import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import { QualitySidebar } from './QualitySidebar';
import { QualityIssuesGrid } from './QualityIssuesGrid';
import { QualityIssueDetail } from './QualityIssueDetail';
import { QualityIssueForm } from './forms/QualityIssueForm';
import { KanbanBoard } from './board/KanbanBoard';
import { ActionListView } from './actionList/ActionListView';
import { Dashboard } from './dashboard/Dashboard';
import { useQualityIssues } from '../../hooks/useQualityIssues';
import { LoadingScreen } from '../common/LoadingScreen';
import { ErrorScreen } from '../common/ErrorScreen';
import { OfflineIndicator } from '../common/OfflineIndicator';
import { CollapseButton } from '../common/CollapseButton';
import type { QualityIssue } from '../../types/quality';

export function QualityDashboard() {
  const { 
    issues, 
    isLoading, 
    error, 
    isOffline, 
    saveIssue, 
    updateIssueStatus,
    deleteIssue 
  } = useQualityIssues();
  
  const [selectedIssue, setSelectedIssue] = useState<QualityIssue | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [editingIssue, setEditingIssue] = useState<QualityIssue | null>(null);
  const [activeView, setActiveView] = useState<'dashboard' | 'action-list' | 'kanban' | 'grid'>('dashboard');
  const [isCollapsed, setIsCollapsed] = useState(false);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorScreen error={error} />;
  }

  const handleEditIssue = (issue: QualityIssue) => {
    const issueToEdit = {
      ...issue,
      resolutionSteps: issue.resolutionSteps ? [...issue.resolutionSteps] : []
    };
    setEditingIssue(issueToEdit);
    setShowForm(true);
  };

  const handleSubmitIssue = async (formData: Omit<QualityIssue, 'id' | 'createdAt' | 'updatedAt'>) => {
    try {
      if (editingIssue) {
        await saveIssue({
          ...formData,
          id: editingIssue.id,
          createdAt: editingIssue.createdAt,
          updatedAt: new Date()
        });
        
        if (selectedIssue?.id === editingIssue.id) {
          setSelectedIssue({
            ...formData,
            id: editingIssue.id,
            createdAt: editingIssue.createdAt,
            updatedAt: new Date()
          });
        }
      } else {
        await saveIssue(formData);
      }
      
      setShowForm(false);
      setEditingIssue(null);
    } catch (err) {
      console.error('Failed to save issue:', err);
    }
  };

  return (
    <div className="flex-1 flex">
      <div className="flex h-full">
        <div className={`transition-transform duration-300 ease-in-out transform ${isCollapsed ? '-translate-x-20' : 'translate-x-0'}`}>
          <QualitySidebar
            activeView={activeView}
            onViewChange={setActiveView}
            isCollapsed={isCollapsed}
          />
        </div>
        <div className="relative flex-shrink-0 -ml-3 z-10">
          <CollapseButton 
            isCollapsed={isCollapsed} 
            onClick={() => setIsCollapsed(!isCollapsed)}
            direction={isCollapsed ? 'right' : 'left'}
          />
        </div>
      </div>

      <div className="flex-1 p-6 overflow-hidden">
        {activeView !== 'dashboard' && (
          <div className="mb-6">
            <button
              onClick={() => setShowForm(true)}
              className="flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <Plus size={20} />
              <span>New Issue</span>
            </button>
          </div>
        )}

        {activeView === 'grid' ? (
          <QualityIssuesGrid
            issues={issues}
            onSelectIssue={setSelectedIssue}
          />
        ) : activeView === 'kanban' ? (
          <KanbanBoard
            issues={issues}
            onUpdateIssue={saveIssue}
            onUpdateStatus={updateIssueStatus}
            onDeleteIssue={deleteIssue}
            onEditIssue={handleEditIssue}
          />
        ) : activeView === 'action-list' ? (
          <ActionListView 
            issues={issues}
            onUpdateIssue={saveIssue}
          />
        ) : (
          <Dashboard issues={issues} />
        )}
      </div>

      {/* Modals */}
      {(showForm || selectedIssue) && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-6 z-50 overflow-y-auto">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-6xl max-h-[90vh] overflow-hidden flex flex-col">
            {showForm ? (
              <QualityIssueForm
                issue={editingIssue}
                onSubmit={handleSubmitIssue}
                onCancel={() => {
                  setShowForm(false);
                  setEditingIssue(null);
                }}
              />
            ) : selectedIssue && (
              <QualityIssueDetail
                issue={selectedIssue}
                onClose={() => setSelectedIssue(null)}
                onEdit={handleEditIssue}
                onDelete={async () => {
                  if (window.confirm('Are you sure you want to delete this issue?')) {
                    await deleteIssue(selectedIssue.id);
                    setSelectedIssue(null);
                  }
                }}
              />
            )}
          </div>
        </div>
      )}

      {isOffline && <OfflineIndicator />}
    </div>
  );
}