import React from 'react';
import { FileText, ExternalLink } from 'lucide-react';
import type { QualityIssue } from '../../../../types/quality';

interface ECOStatsProps {
  issues: QualityIssue[];
}

export function ECOStats({ issues }: ECOStatsProps) {
  const ecoIssues = issues.filter(i => i.ecoRequired);
  const pendingECOs = ecoIssues.filter(i => i.status !== 'resolved');
  const completedECOs = ecoIssues.filter(i => i.status === 'resolved');

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-medium text-gray-900">ECO Status</h3>
        <FileText className="text-indigo-600" size={24} />
      </div>

      <div className="space-y-6">
        {/* Summary Stats */}
        <div className="grid grid-cols-3 gap-4">
          <div className="bg-gray-50 rounded-lg p-4">
            <div className="text-sm text-gray-600">Total ECOs</div>
            <div className="text-2xl font-bold text-gray-900">{ecoIssues.length}</div>
          </div>
          <div className="bg-yellow-50 rounded-lg p-4">
            <div className="text-sm text-yellow-600">Pending</div>
            <div className="text-2xl font-bold text-yellow-900">{pendingECOs.length}</div>
          </div>
          <div className="bg-green-50 rounded-lg p-4">
            <div className="text-sm text-green-600">Completed</div>
            <div className="text-2xl font-bold text-green-900">{completedECOs.length}</div>
          </div>
        </div>

        {/* Recent ECOs */}
        <div>
          <h4 className="text-sm font-medium text-gray-700 mb-4">Recent ECOs</h4>
          <div className="space-y-3">
            {pendingECOs.slice(0, 5).map(issue => (
              <div key={issue.id} className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                <div>
                  <div className="font-medium text-gray-900">{issue.ecoDetails?.number}</div>
                  <div className="text-sm text-gray-600">{issue.title}</div>
                </div>
                {issue.ecoDetails?.link && (
                  <a
                    href={issue.ecoDetails.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 text-blue-600 hover:text-blue-700"
                  >
                    <ExternalLink size={16} />
                  </a>
                )}
              </div>
            ))}
            {pendingECOs.length === 0 && (
              <div className="text-center text-gray-500 py-4">
                No pending ECOs
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}