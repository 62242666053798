import React from 'react';
import type { QualityIssue } from '../../../../types/quality';

interface StatusBreakdownProps {
  issues: QualityIssue[];
}

export function StatusBreakdown({ issues }: StatusBreakdownProps) {
  const statusCounts = issues.reduce((acc, issue) => {
    acc[issue.status] = (acc[issue.status] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  const total = issues.length;
  const statusColors = {
    'open': 'bg-yellow-500',
    'in-progress': 'bg-blue-500',
    'in-review': 'bg-purple-500',
    'resolved': 'bg-green-500'
  };

  const statusLabels = {
    'open': 'Open',
    'in-progress': 'In Progress',
    'in-review': 'In Review',
    'resolved': 'Resolved'
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-6">Status Distribution</h3>
      
      <div className="space-y-6">
        {/* Status Bar */}
        <div className="h-4 flex rounded-full overflow-hidden">
          {Object.entries(statusCounts).map(([status, count]) => (
            <div
              key={status}
              className={`${statusColors[status as keyof typeof statusColors]}`}
              style={{ width: `${(count / total) * 100}%` }}
            />
          ))}
        </div>

        {/* Legend */}
        <div className="grid grid-cols-4 gap-4">
          {Object.entries(statusCounts).map(([status, count]) => (
            <div key={status} className="text-center">
              <div className="text-2xl font-bold text-gray-900">{count}</div>
              <div className="flex items-center justify-center space-x-2 mt-1">
                <div 
                  className={`w-3 h-3 rounded-full ${statusColors[status as keyof typeof statusColors]}`} 
                />
                <span className="text-sm text-gray-600">
                  {statusLabels[status as keyof typeof statusLabels]}
                </span>
              </div>
              <div className="text-sm text-gray-500 mt-1">
                {Math.round((count / total) * 100)}%
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}