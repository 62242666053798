import { useMemo } from 'react';
import type { QualityIssue } from '../../../types/quality';

export function useIssuesByStatus(issues: QualityIssue[]) {
  return useMemo(() => {
    const map = {
      open: [] as QualityIssue[],
      'in-progress': [] as QualityIssue[],
      'in-review': [] as QualityIssue[],
      resolved: [] as QualityIssue[],
    };
    
    issues.forEach(issue => {
      map[issue.status].push(issue);
    });
    
    return map;
  }, [issues]);
}