import React, { useRef, useState } from 'react';
import { TimelineFilter } from './TimelineFilter';
import { OverallStats } from './stats/OverallStats';
import { TimelineChart } from './charts/TimelineChart';
import { DistributionStats } from './stats/DistributionStats';
import { TimeMetrics } from './stats/TimeMetrics';
import { StatusBreakdown } from './charts/StatusBreakdown';
import { TrendChart } from './charts/TrendChart';
import { TrendAnalysis } from './charts/TrendAnalysis';
import { RootCauseAnalysis } from './charts/RootCauseAnalysis';
import { UserAnalysis } from './charts/UserAnalysis';
import { ActionStepStats } from './stats/ActionStepStats';
import { ECOStats } from './stats/ECOStats';
import { ExportButton } from './ExportButton';
import { exportDashboardAsPDF } from '../../../utils/export/dashboardExport';
import { filterIssuesByTimeframe } from '../../../utils/quality/timelineUtils';
import type { QualityIssue } from '../../../types/quality';

interface QualityDashboardProps {
  issues: QualityIssue[];
}

export function QualityDashboard({ issues }: QualityDashboardProps) {
  const [timeframe, setTimeframe] = useState<'day' | 'week' | 'month' | 'custom'>('week');
  const [dateRange, setDateRange] = useState<{ start: Date; end: Date } | null>(null);
  const dashboardRef = useRef<HTMLDivElement>(null);

  const handleExport = async () => {
    if (!dashboardRef.current) return false;
    return exportDashboardAsPDF(dashboardRef.current);
  };

  // Filter issues based on timeframe and date range
  const filteredIssues = filterIssuesByTimeframe(issues, timeframe, dateRange);

  return (
    <div className="space-y-6 overflow-y-auto max-h-[calc(100vh-12rem)] px-4">
      {/* Header with Export Button */}
      <div className="sticky top-0 z-10 bg-gray-50 -mx-4 px-4 py-2">
        <div className="flex justify-between items-center">
          <TimelineFilter 
            timeframe={timeframe}
            onTimeframeChange={setTimeframe}
            dateRange={dateRange}
            onDateRangeChange={setDateRange}
          />
          <ExportButton onExport={handleExport} />
        </div>
      </div>

      {/* Dashboard Content */}
      <div ref={dashboardRef}>
        <OverallStats issues={filteredIssues} />

        {/* Three Column Layout */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-6">
          {/* Column 1 */}
          <div className="space-y-6">
            <TimelineChart issues={filteredIssues} />
            <TrendAnalysis issues={filteredIssues} timeframe={timeframe} />
            <RootCauseAnalysis issues={filteredIssues} />
          </div>

          {/* Column 2 */}
          <div className="space-y-6">
            <StatusBreakdown issues={filteredIssues} />
            <ECOStats issues={filteredIssues} />
            <TimeMetrics issues={filteredIssues} />
          </div>

          {/* Column 3 */}
          <div className="space-y-6">
            <ActionStepStats issues={filteredIssues} />
            <DistributionStats issues={filteredIssues} />
            <UserAnalysis issues={filteredIssues} />
          </div>
        </div>
      </div>
    </div>
  );
}