import React from 'react';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { KanbanColumn } from './KanbanColumn';
import { KanbanCard } from './KanbanCard';
import { useDragAndDrop } from './useDragAndDrop';
import { useIssuesByStatus } from './useIssuesByStatus';
import type { QualityIssue } from '../../../types/quality';

interface KanbanBoardProps {
  issues: QualityIssue[];
  onUpdateIssue: (issue: QualityIssue) => void;
  onUpdateStatus: (issueId: string, newStatus: QualityIssue['status']) => void;
  onDeleteIssue: (id: string) => void;
  onEditIssue: (issue: QualityIssue) => void;
}

export function KanbanBoard({ 
  issues, 
  onUpdateStatus,
  onDeleteIssue,
  onEditIssue 
}: KanbanBoardProps) {
  const { activeId, sensors, handleDragStart, handleDragEnd } = useDragAndDrop(onUpdateStatus);
  const issuesByStatus = useIssuesByStatus(issues);
  const activeIssue = activeId ? issues.find(i => i.id === activeId) : null;

  return (
    <DndContext 
      sensors={sensors} 
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <div className="h-full grid grid-cols-4 gap-6">
        <KanbanColumn 
          title="Open" 
          status="open"
          count={issuesByStatus.open.length}
        >
          {issuesByStatus.open.map(issue => (
            <KanbanCard 
              key={issue.id} 
              issue={issue} 
              onDelete={onDeleteIssue}
              onEdit={onEditIssue}
            />
          ))}
        </KanbanColumn>

        <KanbanColumn 
          title="In Progress" 
          status="in-progress"
          count={issuesByStatus['in-progress'].length}
        >
          {issuesByStatus['in-progress'].map(issue => (
            <KanbanCard 
              key={issue.id} 
              issue={issue} 
              onDelete={onDeleteIssue}
              onEdit={onEditIssue}
            />
          ))}
        </KanbanColumn>

        <KanbanColumn 
          title="In Review" 
          status="in-review"
          count={issuesByStatus['in-review'].length}
        >
          {issuesByStatus['in-review'].map(issue => (
            <KanbanCard 
              key={issue.id} 
              issue={issue} 
              onDelete={onDeleteIssue}
              onEdit={onEditIssue}
            />
          ))}
        </KanbanColumn>

        <KanbanColumn 
          title="Resolved" 
          status="resolved"
          count={issuesByStatus.resolved.length}
        >
          {issuesByStatus.resolved.map(issue => (
            <KanbanCard 
              key={issue.id} 
              issue={issue} 
              onDelete={onDeleteIssue}
              onEdit={onEditIssue}
            />
          ))}
        </KanbanColumn>
      </div>

      <DragOverlay>
        {activeIssue ? (
          <div className="w-[calc(100%/4-1.5rem)]">
            <KanbanCard 
              issue={activeIssue}
              onDelete={onDeleteIssue}
              onEdit={onEditIssue}
              isDragOverlay
            />
          </div>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}