import React from 'react';
import { BarChart2, List, KanbanSquare, LayoutGrid } from 'lucide-react';

interface QualitySidebarProps {
  activeView: 'dashboard' | 'action-list' | 'kanban' | 'grid';
  onViewChange: (view: 'dashboard' | 'action-list' | 'kanban' | 'grid') => void;
  isCollapsed: boolean;
}

export function QualitySidebar({ 
  activeView, 
  onViewChange,
  isCollapsed
}: QualitySidebarProps) {
  return (
    <div className="w-20 bg-gray-50 border-r border-gray-200 shadow-sm flex flex-col items-center py-4 h-full">
      <div className="space-y-6 flex flex-col items-center w-full">
        <button
          onClick={() => onViewChange('dashboard')}
          className={`w-16 flex flex-col items-center p-2 rounded-lg transition-colors ${
            activeView === 'dashboard'
              ? 'bg-blue-50 text-blue-600'
              : 'text-gray-600 hover:text-gray-900 hover:bg-gray-100'
          }`}
          title="Dashboard"
        >
          <BarChart2 size={24} />
          <span className="text-[10px] mt-1">Dashboard</span>
        </button>

        <button
          onClick={() => onViewChange('action-list')}
          className={`w-16 flex flex-col items-center p-2 rounded-lg transition-colors ${
            activeView === 'action-list'
              ? 'bg-blue-50 text-blue-600'
              : 'text-gray-600 hover:text-gray-900 hover:bg-gray-100'
          }`}
          title="Action List"
        >
          <List size={24} />
          <span className="text-[10px] mt-1">Actions</span>
        </button>

        <button
          onClick={() => onViewChange('kanban')}
          className={`w-16 flex flex-col items-center p-2 rounded-lg transition-colors ${
            activeView === 'kanban'
              ? 'bg-blue-50 text-blue-600'
              : 'text-gray-600 hover:text-gray-900 hover:bg-gray-100'
          }`}
          title="Kanban Board"
        >
          <KanbanSquare size={24} />
          <span className="text-[10px] mt-1">Kanban</span>
        </button>

        <button
          onClick={() => onViewChange('grid')}
          className={`w-16 flex flex-col items-center p-2 rounded-lg transition-colors ${
            activeView === 'grid'
              ? 'bg-blue-50 text-blue-600'
              : 'text-gray-600 hover:text-gray-900 hover:bg-gray-100'
          }`}
          title="Grid View"
        >
          <LayoutGrid size={24} />
          <span className="text-[10px] mt-1">Grid</span>
        </button>
      </div>
    </div>
  );
}